<!--
 * @version: 1.0
 * @Date: 2023-05-17 13:45:50
 * @LastEditors: 杨慢慢
-->
<template>
  <div class="select-input-wrapper design-form-style">
    <div class="flex-layout">
      <div class="label">
        <span class="required-icon" v-if="required || title === '盖印人'">*</span>
        <span>{{title}}</span>
      </div>
      <div class="placeholder" v-if="title !== '盖印人'">
        {{ placeholder || '请选择' }}
        <RightOutlined style="color: rgba(0, 0, 0, 0.25); font-size: 14px" />
      </div>
      <div class="placeholder" v-else>
        <span v-for="item in stamp" :class="{'stamping':true, 'stamping-no-check': !item.isCheck, 'stamping-check': item.isCheck}" :key="item.title">{{item.title}}</span>
      </div>
    </div>
    <div :class="{'tips': true, 'tips-label': required }" v-if="tipsText">{{ tipsText }}</div>
  </div>
</template>

<script setup>
import { RightOutlined } from '@ant-design/icons-vue'
import { onMounted, ref } from 'vue';
const props = defineProps(['title','placeholder', 'tipsText', 'required', 'stamp'])
// const stamp = ref([])
onMounted(() => {
  // console.log(props);
  // if(!props.stamp) {
  //   stamp.value = [
  //     {title: '申请人', isCheck: false},
  //     {title: '保管员', isCheck: true},
  //   ]
  // }
})
</script>

<style lang='less' scoped>
.flex-layout {
  justify-content: space-between;
}
.stamping-no-check {
  background: #EAEAEA;
  color: #333;
}
.stamping-check {
  background: #FFF;
  border: 1px solid #0A7BFF;
  color: #0A7BFF;
}
.stamping {
  border-radius: 4px;
  padding: 5px 20px;
  margin-left: 8px;
}
.required-icon{
  color:#c3161c !important;
}
</style>
195
365