<!--
 * @version: 1.0
 * @Date: 2023-05-17 13:45:50
 * @LastEditors: 杨慢慢
-->
<template>
  <div>
    <div v-for="(it, index) in items" :key="index" class="seal-item">
      <FormDesignRender :config="it" />
    </div>
  </div>
</template>
<script setup>
import { defineProps } from 'vue'
import FormDesignRender from '../commonViews/formDesignRender.vue'
defineProps(['title', 'items'])
</script>

<style lang='less' scoped>
.seal-item {
  padding: 10px 0 10px;
}
.seal-item:first-of-type{
  padding-top: 0;
}
.seal-item:last-of-type{
  padding-bottom: 0;
}
</style>