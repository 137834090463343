<!--
 * @version: 1.0
 * @Date: 2023-04-25 17:06:58
 * @LastEditors: 杨慢慢
-->
<template>
  <component
    :is="components[config.controlName]"
    v-bind="config.props"
    :title="config.title"
    :allDisabled="config.allDisabled"
  />
</template>
<script setup>
import components from '../componentExport'
import { defineProps } from 'vue'
defineProps(['config'])
</script>

<style lang="less" scoped></style>
