<!--
 * @version: 1.0
 * @Date: 2023-05-17 13:45:50
 * @LastEditors: 杨慢慢
-->
<template>
  <div>
    <div class="takeout-wrapper design-form-style">
      <div class="flex-layout">
        <div class="label">
          <span>{{ title }}</span>
        </div>
        <div>
          <a-switch v-model:checked="checked" :disabled="allDisabled" />
        </div>
      </div>
      <div class="tips" v-if="tipsText">{{ tipsText }}</div>
    </div>
    <div v-if="checked">
      <div v-for="(it, index) in items" :key="index" class="takeout-item">
        <FormDesignRender :config="it" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref } from 'vue'
import FormDesignRender from '../commonViews/formDesignRender.vue'
defineProps(['title', 'items', 'tipsText', 'allDisabled'])
const checked = ref(true)
</script>

<style lang="less" scoped>
.flex-layout {
  justify-content: space-between;
}
.takeout-item {
  padding: 10px 0 0px;
  // margin-top: 18px;
}
.takeout-item:first-of-type {
  padding-top: 0;
}
.takeout-item:last-of-type {
  padding-bottom: 0;
}
</style>
