<template>
  <div class="textarea-input-wrapper design-special-style">
    <div class="">
      <div class="label">
      <span class="required-icon" v-if="required">*</span>
      <span>{{title}}</span>
      </div>
      <div class="placeholder">{{placeholder || '请输入'}}</div>
    </div>
    <div class="tips" v-if="tipsText">{{tipsText}}</div>
  </div>
</template>

<script setup>
defineProps(['title','placeholder','tipsText','required'])
</script>

<style lang='less' scoped>
.textarea-input-wrapper {
  justify-content: space-between;
  font-size: 16px;
}
.placeholder {
  // width: 456px;
  height: 120px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  padding:16px ;
  margin-top:8px ;
}
.required-icon{
  color:#c3161c !important;
}
</style>